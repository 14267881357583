@use "variables";
@use "mixins";

dialog {
  border: .15rem solid variables.$dialog-edge;
  box-shadow: 0 0 1rem variables.$dialog-edge;
  border-radius: 1rem;
  color: variables.$dialog-fg;
  background-color: variables.$dialog-bg;
  padding: 2rem;
  font-style: normal;
  // OBS Browser Source interaction window is tiny by default, so this has a larger font-size
  font-size: 1.75rem;
  cursor: initial;

  h1, h2, h3 {
    margin: 0 0 1rem;
    font-weight: normal;
  }

  details {
    margin-bottom: 1em;
    border-left: .05em solid;
    padding-left: 1em;

    summary {
      cursor: pointer;

      h1, h2, h3 {
        display: inline-block;
        margin: 0;
      }
    }
  }

  p {
    margin: 0 0 .25rem;
  }

  a {
    color: rgba(220, 220, 255);

    &:visited {
      color: rgba(255, 220, 255);
    }
  }

  input[type="text"], input[type="number"], input[type="password"], input[type="url"], textarea {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    @include mixins.input;
  }

  input[type="checkbox"], input[type="radio"] {
    width: 1em;
    height: 1em;
    font-size: 1em;
    line-height: 1em;
  }

  input, textarea {
    &:focus {
      border-bottom-color: variables.$dialog-input-border-focus;
    }

    &.small {
      font-size: 1rem;
      padding: .75rem;
    }
  }

  button {
    font-style: normal;
    @include mixins.input;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &.inline {
      margin: 0 .5em;
    }
  }

  code {
    font-size: .75em;
    border-radius: .15em;
    background-color: #313131;
    padding: .2em;
  }
}
