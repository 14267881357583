$text-color: #fff;
$mapper-text-color: #67C271;
$font-size: 3.5rem;
$edge-spacing: 1rem;
$outline-size: .75px;
$outline-color: darken(#30466E, 10%);
$cover-size: 2.85em;
$image-spacing: $cover-size * 0.2;
$transition-delay: .05s;
$base-font: 'Kalam', cursive;
$special-font: 'Teko', sans-serif;
$ease-in-out-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);
$ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

$success-color: #aea;
$error-color: #eaa;

$z-index-map: (
        "notes-pile": 1,
        "bouncy": 2,
        "credits": 2,
        "heart-rate": 3,
        "beat-saber": 4,
);

$dialog-bg: #101010;
$dialog-fg: #ddd;
$dialog-edge: #555;
$dialog-input-border: #808080;
$dialog-input-border-focus: $dialog-fg;
