@use "variables";

@mixin special-font {
  font-family: variables.$special-font;
  font-style: italic;
}

@mixin outline {
  $shadow: '';
  @for $i from -1 through 1 {
    @if $i != 0 {
      @for $j from -1 through 1 {
        @if $j != 0 {
          $shadow: $shadow + ' ' + drop-shadow(#{$i}px #{$j}px variables.$outline-size variables.$outline-color);
        }
      }
    }
  }
  filter: #{$shadow};
}

@mixin loading-element($loader-size, $color: variables.$text-color, $frozen: false) {
  $loader-spacing: .2;
  $loader-thickness-multiplier: .08;
  $loader-rotations-per-cycle: 3;
  $loader-border-opacity: .35;
  $loader-thickness: calc(#{$loader-size} * #{$loader-thickness-multiplier});

  border-radius: 50%;
  border: $loader-thickness solid rgba($color, $loader-border-opacity);
  @if ($frozen != true) {
    border-top-color: $color;
    animation: rotate 2s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }
  transform-origin: 50% 50%;
  $size: "#{$loader-size} * #{1 - $loader-spacing}";
  width: calc(#{$size});
  height: calc(#{$size});
  margin: calc((#{$loader-size} - #{$size}) * 0.5);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(#{360 * $loader-rotations-per-cycle}deg);
    }
  }
}

@mixin dots-between-children {
  > :not(:first-child) {
    &:before {
      content: "•";
      margin: 0 .25em;
      opacity: .5;
      color: #fff;
    }
  }
}


@mixin input {
  color: variables.$dialog-fg;
  background-color: transparent;
  border: 2px solid variables.$dialog-input-border;
  border-radius: .25em;
  padding: .25em .75em;
  line-height: 1;
  box-sizing: border-box;
  font-size: inherit;

  &:disabled {
    opacity: .5;
  }

  &:active {
    border-color: variables.$dialog-input-border-focus;
  }
}
