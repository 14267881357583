video.oKJSWq_bouncy {
  width: 112px;
  height: 112px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  margin: 1rem;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

video.oKJSWq_bouncy.oKJSWq_show {
  opacity: 1;
}

.DZJW0G_channel-bug {
  z-index: 2;
  text-align: left;
  opacity: 1;
  cursor: pointer;
  margin: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

.DZJW0G_logo-image {
  opacity: .25;
  width: 14rem;
}

.jdDt9G_background {
  z-index: -1;
  cursor: pointer;
  background: #000 url("transparency.0f18b9bb.svg") 0 0 / 32px;
  position: fixed;
  inset: 0;
}

.beat-saver-map_3883d4 {
  display: block;
}

.beat-saver-map_3883d4.in-chat_3883d4 {
  margin-top: .5em;
  font-size: .8em;
}

.beat-saver-map_3883d4 .beat-saver-song-info_3883d4 {
  flex-flow: row-reverse;
  font-family: Teko, sans-serif;
  font-size: 1.5em;
  display: flex;
  transform: skew(-8deg);
}

.beat-saver-map_3883d4 .beat-saver-song-info_3883d4 .song-info-block {
  box-sizing: border-box;
  padding-left: .35em;
}

.beat-saver-map_3883d4 .beat-saver-song-info_3883d4 .song-info-block .song-info-line.song-name {
  font-size: 1em;
}

.beat-saver-map_3883d4 .beat-saver-song-info_3883d4 .song-info-block .song-info-line.song-author, .beat-saver-map_3883d4 .beat-saver-song-info_3883d4 .song-info-block .song-info-line.song-details {
  font-size: .75em;
}

.uWTHqG_tts-health {
  z-index: 10;
  box-sizing: border-box;
  color: #ff0000bf;
  width: calc(100% - 2em);
  cursor: pointer;
  background-color: #280000;
  border-radius: .4em;
  padding: .5em;
  font-size: .6em;
  transition: opacity .3s linear;
  position: absolute;
  top: 0;
  left: 1rem;
  right: 0;
}

.uWTHqG_tts-health:hover {
  opacity: 1 !important;
}

.uWTHqG_tts-health .uWTHqG_tts-label {
  flex-flow: row;
  justify-content: space-between;
  display: flex;
}

.uWTHqG_tts-health .uWTHqG_tts-label .uWTHqG_tts-name {
  font-weight: bold;
}

.uWTHqG_tts-health .uWTHqG_tts-label .uWTHqG_tts-percent {
  text-align: right;
}

.uWTHqG_tts-health .uWTHqG_progress {
  height: .2em;
  width: 100%;
  appearance: none;
  background-color: #202020;
  border: .125em solid #202020;
  border-radius: .2em;
  display: block;
}

.uWTHqG_tts-health .uWTHqG_progress .uWTHqG_progress-bar {
  border-radius: inherit;
  height: 100%;
  background-color: currentColor;
}

dialog {
  color: #ddd;
  cursor: initial;
  background-color: #101010;
  border: .15rem solid #555;
  border-radius: 1rem;
  padding: 2rem;
  font-size: 1.75rem;
  font-style: normal;
  box-shadow: 0 0 1rem #555;
}

dialog h1, dialog h2, dialog h3 {
  margin: 0 0 1rem;
  font-weight: normal;
}

dialog details {
  border-left: .05em solid;
  margin-bottom: 1em;
  padding-left: 1em;
}

dialog details summary {
  cursor: pointer;
}

dialog details summary h1, dialog details summary h2, dialog details summary h3 {
  margin: 0;
  display: inline-block;
}

dialog p {
  margin: 0 0 .25rem;
}

dialog a {
  color: #dcdcff;
}

dialog a:visited {
  color: #ffdcff;
}

dialog input[type="text"], dialog input[type="number"], dialog input[type="password"], dialog input[type="url"], dialog textarea {
  width: 100%;
  color: #ddd;
  box-sizing: border-box;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  margin-bottom: 1rem;
  padding: .25em .75em;
  display: block;
}

dialog input[type="text"]:disabled, dialog input[type="number"]:disabled, dialog input[type="password"]:disabled, dialog input[type="url"]:disabled, dialog textarea:disabled {
  opacity: .5;
}

dialog input[type="text"]:active, dialog input[type="number"]:active, dialog input[type="password"]:active, dialog input[type="url"]:active, dialog textarea:active {
  border-color: #ddd;
}

dialog input[type="checkbox"], dialog input[type="radio"] {
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1em;
}

dialog input:focus, dialog textarea:focus {
  border-bottom-color: #ddd;
}

dialog input.Udi8bG_small, dialog textarea.Udi8bG_small {
  padding: .75rem;
  font-size: 1rem;
}

dialog button {
  color: #ddd;
  box-sizing: border-box;
  font-style: normal;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  padding: .25em .75em;
}

dialog button:disabled {
  opacity: .5;
}

dialog button:active {
  border-color: #ddd;
}

dialog button:not(:last-child) {
  margin-right: 1rem;
}

dialog button.Udi8bG_inline {
  margin: 0 .5em;
}

dialog code {
  background-color: #313131;
  border-radius: .15em;
  padding: .2em;
  font-size: .75em;
}

.Udi8bG_settings-navigation {
  flex-flow: column;
  flex: 0 0 20rem;
  justify-content: flex-start;
  display: flex;
}

.Udi8bG_settings-navigation .Udi8bG_nav-button {
  text-align: left;
  width: auto;
  cursor: pointer;
  margin: 0 0 .5em;
}

.Udi8bG_settings-navigation .Udi8bG_nav-button[disabled] {
  cursor: not-allowed;
}

.Udi8bG_settings-navigation .Udi8bG_nav-button.Udi8bG_nav-current {
  cursor: default;
  background-color: #808080bf;
}

.qDdJxG_element-settings-tree {
  padding: .5rem 0;
}

.qDdJxG_element-settings-tree:not([data-level="0"]):not([data-level="1"]) {
  border-left: .05em solid;
  padding-left: 1.5em;
}

._56bfyq_labelled-input {
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .4em;
  padding-bottom: .3em;
  display: flex;
}

._56bfyq_labelled-input input[type="checkbox"], ._56bfyq_labelled-input input[type="radio"] {
  flex: none;
}

._56bfyq_labelled-input label {
  flex: 100%;
}

._56bfyq_labelled-input label p {
  opacity: .85;
  margin: 0;
  font-size: .85em;
}

.c3kg-q_import-form .c3kg-q_import-success {
  color: #aea;
}

.c3kg-q_import-form .c3kg-q_import-error {
  color: #eaa;
}

.c3kg-q_import-form .c3kg-q_export-output {
  margin-top: 1em;
}

.c3kg-q_import-form .c3kg-q_export-output .c3kg-q_export-code {
  -webkit-user-select: all;
  user-select: all;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: 1em;
  padding: .75em;
  display: block;
}

.WD3sOW_obs-control-levels {
  margin: 0 0 1rem;
  padding-left: 2rem;
}

.WD3sOW_obs-control-levels li {
  color: #daa;
}

.WD3sOW_obs-control-levels li:after {
  content: "×";
  margin-left: .5em;
}

.WD3sOW_obs-control-levels li.WD3sOW_access-granted {
  color: #ada;
}

.WD3sOW_obs-control-levels li.WD3sOW_access-granted:after {
  content: "✓";
}

.KI4Btq_installed-mods .KI4Btq_mod-title {
  margin: 0 0 .25em;
  font-size: 1em;
  font-weight: bold;
}

.KI4Btq_installed-mods .KI4Btq_mod-title .KI4Btq_mod-name {
  font-size: 1em;
}

.KI4Btq_installed-mods .KI4Btq_mod-title .KI4Btq_mod-version, .KI4Btq_installed-mods .KI4Btq_mod-info {
  font-size: .8em;
}

.KI4Btq_installed-mods ul {
  width: 100%;
  text-align: center;
  flex-flow: wrap;
  justify-content: space-around;
  gap: .75em;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.KI4Btq_installed-mods ul li {
  box-sizing: border-box;
  border: .125em solid;
  border-radius: .3em;
  padding: .5em;
}

dialog {
  color: #ddd;
  cursor: initial;
  background-color: #101010;
  border: .15rem solid #555;
  border-radius: 1rem;
  padding: 2rem;
  font-size: 1.75rem;
  font-style: normal;
  box-shadow: 0 0 1rem #555;
}

dialog h1, dialog h2, dialog h3 {
  margin: 0 0 1rem;
  font-weight: normal;
}

dialog details {
  border-left: .05em solid;
  margin-bottom: 1em;
  padding-left: 1em;
}

dialog details summary {
  cursor: pointer;
}

dialog details summary h1, dialog details summary h2, dialog details summary h3 {
  margin: 0;
  display: inline-block;
}

dialog p {
  margin: 0 0 .25rem;
}

dialog a {
  color: #dcdcff;
}

dialog a:visited {
  color: #ffdcff;
}

dialog input[type="text"], dialog input[type="number"], dialog input[type="password"], dialog input[type="url"], dialog textarea {
  width: 100%;
  color: #ddd;
  box-sizing: border-box;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  margin-bottom: 1rem;
  padding: .25em .75em;
  display: block;
}

dialog input[type="text"]:disabled, dialog input[type="number"]:disabled, dialog input[type="password"]:disabled, dialog input[type="url"]:disabled, dialog textarea:disabled {
  opacity: .5;
}

dialog input[type="text"]:active, dialog input[type="number"]:active, dialog input[type="password"]:active, dialog input[type="url"]:active, dialog textarea:active {
  border-color: #ddd;
}

dialog input[type="checkbox"], dialog input[type="radio"] {
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1em;
}

dialog input:focus, dialog textarea:focus {
  border-bottom-color: #ddd;
}

dialog input._9XRxLa_small, dialog textarea._9XRxLa_small {
  padding: .75rem;
  font-size: 1rem;
}

dialog button {
  color: #ddd;
  box-sizing: border-box;
  font-style: normal;
  line-height: 1;
  font-size: inherit;
  background-color: #0000;
  border: 2px solid gray;
  border-radius: .25em;
  padding: .25em .75em;
}

dialog button:disabled {
  opacity: .5;
}

dialog button:active {
  border-color: #ddd;
}

dialog button:not(:last-child) {
  margin-right: 1rem;
}

dialog button._9XRxLa_inline {
  margin: 0 .5em;
}

dialog code {
  background-color: #313131;
  border-radius: .15em;
  padding: .2em;
  font-size: .75em;
}

._9XRxLa_settings-dialog {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  box-sizing: border-box;
  flex-flow: row;
  align-items: stretch;
  gap: 1.5em;
  position: relative;
}

._9XRxLa_settings-dialog:not([hidden]) {
  display: flex;
}

._9XRxLa_settings-dialog._9XRxLa_full-size {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: none;
  margin: auto;
}

._9XRxLa_settings-dialog ._9XRxLa_close-button-wrap {
  width: 0;
  flex: none;
}

._9XRxLa_settings-dialog ._9XRxLa_close-button-wrap ._9XRxLa_close-button {
  width: 1em;
  height: 1em;
  z-index: 2;
  box-sizing: content-box;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: .25em;
  font-size: 1.5em;
  position: absolute;
  transform: translate(-.75em, -.75em);
}

._9XRxLa_settings-navigation {
  flex-flow: column;
  flex: 0 0 20rem;
  justify-content: flex-start;
  display: flex;
}

._9XRxLa_settings-navigation ._9XRxLa_nav-button {
  text-align: left;
  width: auto;
  cursor: pointer;
  margin: 0 0 .5em;
}

._9XRxLa_settings-navigation ._9XRxLa_nav-button[disabled] {
  cursor: not-allowed;
}

._9XRxLa_settings-navigation ._9XRxLa_nav-button._9XRxLa_nav-current {
  background-color: gray;
}

._9XRxLa_settings-ui-wrap {
  box-sizing: border-box;
  flex: auto;
  padding-right: 1em;
  display: block;
  overflow: auto;
}

._9XRxLa_settings-header {
  z-index: 1;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: #101010d9;
  position: sticky;
  top: 0;
}

._9XRxLa_settings-header ._9XRxLa_muted {
  opacity: .5;
}

/*# sourceMappingURL=index.5b99216f.css.map */
